// src/components/Header.js
import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { FaBars, FaTimes } from "react-icons/fa"; // Import hamburger and close icons
import "../style/Header.css";
const Header = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      const scrollPosition = window.scrollY + 70; // Offset for header height

      sections.forEach((section) => {
        if (
          section.offsetTop <= scrollPosition &&
          section.offsetTop + section.offsetHeight > scrollPosition
        ) {
          setActiveLink(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="header">
      <nav>
        <ul className="nav-list">
          <li className="start-item">
            <img src="/AJ.ico" alt="Icon" className="icon" />
          </li>
          <li className={`nav-links ${isMobileMenuOpen ? "open" : ""}`}>
            <Link
              to="home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className={`nav-link ${activeLink === "home" ? "active" : ""}`}
              onClick={toggleMobileMenu} // Close menu on link click
            >
              Home
            </Link>
            <Link
              to="about"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className={`nav-link ${activeLink === "about" ? "active" : ""}`}
              onClick={toggleMobileMenu} // Close menu on link click
            >
              About
            </Link>
            <Link
              to="timeline"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className={`nav-link ${
                activeLink === "timeline" ? "active" : ""
              }`}
              onClick={toggleMobileMenu} // Close menu on link click
            >
              Timeline
            </Link>
            <Link
              to="projects"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className={`nav-link ${
                activeLink === "projects" ? "active" : ""
              }`}
              onClick={toggleMobileMenu} // Close menu on link click
            >
              Projects
            </Link>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className={`nav-link ${activeLink === "contact" ? "active" : ""}`}
              onClick={toggleMobileMenu} // Close menu on link click
            >
              Contact
            </Link>
          </li>
          <li className="hamburger" onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
