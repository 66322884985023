import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Timeline from "./components/Timeline";
import Projects from "./components/Portfolio";
import Contact from "./components/Contact";
import "./index.css";
// index.js or App.js

const App = () => {
  return (
    <Router>
      <Header />
      <main>
        <Home />
        <About />
        <Timeline />
        <Projects />
        <Contact />
      </main>
      <Footer />
    </Router>
  );
};

export default App;
