import React from "react";
import "../style/Timeline.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faSchool } from "@fortawesome/free-solid-svg-icons";

const timelineData = [
  {
    date: "January 2024 - April 2024",
    title: "Teaching Assistant - Web Programming",
    subtitle: "Concordia University",
    type: "job",
  },
  {
    date: "Sept 2022 - May 2024",
    title: "Masters in Applied Computer Science",
    subtitle: "Concordia University",
    type: "education",
  },
  {
    date: "July 2021 - July 2022",
    title: "Associate Software Engineer",
    subtitle: "OpenText Technologies Pvt Ltd",
    type: "job",
  },
  {
    date: "May 2021 - July 2021",
    title: "Software Engineering Intern",
    subtitle: "OpenText Technologies Pvt Ltd",
    type: "job",
  },
  {
    date: "May 2019 - June 2019",
    title: "Android Developer Intern",
    subtitle: "CubeSquare Technologies Pvt Ltd",
    type: "job",
  },
  {
    date: "May 2017 - May 2021",
    title: "B.Tech Computer Science and Engineering",
    subtitle: "S.R.M Institute of Science and Technology",
    type: "education",
  },
];

const getIcon = (type) => {
  switch (type) {
    case "education":
      return faSchool;
    case "job":
      return faBriefcase;
    default:
      return faBriefcase; // Default to job icon if type is not recognized
  }
};

const Timeline = () => {
  return (
    <section id="timeline" className="timeline-container">
      <div className="timeline-container">
        <h1 className="timeline-heading">Timeline</h1>
        <VerticalTimeline>
          {timelineData.map((item, index) => (
            <VerticalTimelineElement
              key={index}
              date={item.date}
              iconStyle={{ background: "#6a0dad", color: "#fff" }}
              icon={<FontAwesomeIcon icon={getIcon(item.type)} />}
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "#000",
                color: "#fff",
                border: "1px solid #6a0dad",
              }}
              contentArrowStyle={{ borderRight: "7px solid  #6a0dad" }}
            >
              <h3 className="vertical-timeline-element-title">{item.title}</h3>
              <h4 className="vertical-timeline-element-subtitle">
                {item.subtitle}
              </h4>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    </section>
  );
};

export default Timeline;
