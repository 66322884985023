import React from "react";
import "../style/Footer.css"; // Import your footer CSS file if separate styling is used
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faWhatsapp,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

const Footer = () => {
  return (
    <footer className="footer-container">
      {/* Footer content */}
      <div className="social-icons">
        <a
          href="https://www.linkedin.com/in/antas-jain"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedin} className="icon" />
        </a>
        <a
          href="https://github.com/antasjain"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faGithub} className="icon" />
        </a>
        <a
          href="https://twitter.com/antasjain"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faTwitter} className="icon" />
        </a>
        <a
          href="mailto:jainantas@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faEnvelope} className="icon" />
        </a>
        <a
          href="https://wa.me/5149651608"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faWhatsapp} className="icon" />
        </a>
      </div>
      <p className="copyright">&copy; 2024 Antas Jain. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
