// src/components/Home.js
import React from "react";
import "../style/Home.css"; // Import CSS for styling
import portfolio from "../antasjainprofile.png";

const Home = () => {
  return (
    <section id="home" className="home-container">
      <img
        src={portfolio}
        alt="Portfolio Circular"
        className="portfolio-image"
      />
      <h1>Antas Jain</h1>
      <h2>Software Developer</h2>
      <a
        href="jainantas.99@gmail.com_resume.pdf"
        className="resume-button"
        download
      >
        Download Resume
      </a>
    </section>
  );
};

export default Home;
